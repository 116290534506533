import { Shop } from "@mui/icons-material";
import Cube from "examples/Icons/Cube";
import Document from "examples/Icons/Document";
import SpaceShip from "examples/Icons/SpaceShip";
import icon1 from "./Image/icon 1.png";
import icon2 from "./Image/icon 2.png";
import icon3 from "./Image/icon 3.png";
import icon4 from "./Image/icon 4.png";
import icon5 from "./Image/icon 5.png";
import icon6 from "./Image/icon 6.png";
import icon7 from "./Image/icon 7.png";
import icon8 from "./Image/icon 8.png";
import icon9 from "./Image/icon 9.png";
import icon10 from "./Image/icon 10.png";
const userRole = localStorage.getItem("userRole");
const demandCollapse = [
  {
    name: "Live Demand",
    key: "getting-started",
    href: "/dashboards/default",
    description: "Live Demand - SAP vs Budget",
    icon: (
      <img
        style={{ width: "20px", height: "20px", margin: "0 auto" }}
        src={icon1}
        alt="Live Demand"
      />
    ),
  },
  {
    name: "Demand Value",
    key: "demand-price",
    href: "/dashboard-2",
    description: "Live Demand (SAP vs Budget) in INR",
    icon: (
      <img
        style={{ width: "20px", height: "20px", margin: "0 auto" }}
        src={icon2}
        alt="Live Demand"
      />
    ),
  },
  {
    name: "Demand Detailed",
    key: "demand-detailed",
    href: "/dashboard-3",
    description: "Live Demand with 5 days history",
    icon: (
      <img
        style={{ width: "20px", height: "20px", margin: "0 auto" }}
        src={icon3}
        alt="Live Demand"
      />
    ),
  },
  // {
  //   name: "Cumulative Demand",
  //   key: "production-demand",
  //   href: "/productionLive-demand",
  //   description: "Cumulative Demand & Invoice",
  //   icon: (
  //     <img
  //       style={{ width: "20px", height: "20px", margin: "0 auto" }}
  //       src={icon4}
  //       alt="Live Demand"
  //     />
  //   ),
  // },
  {
    name: "Live Demand-SO",
    key: "production-demand - SO",
    href: "/livedemand-So",
    description: "Live Demand from Sales Orders",
    icon: (
      <img
        style={{ width: "20px", height: "20px", margin: "0 auto" }}
        src={icon4}
        alt="Live Demand"
      />
    ),
  },
];
const demandCollapse2 = [
  {
    name: "Live Demand",
    key: "getting-started",
    href: "/dashboards/default",
    description: "Live Demand - SAP vs Budget",
    icon: (
      <img
        style={{ width: "20px", height: "20px", margin: "0 auto" }}
        src={icon1}
        alt="Live Demand"
      />
    ),
  },
  {
    name: "Demand Value",
    key: "demand-price",
    href: "/dashboard-2",
    description: "Live Demand (SAP vs Budget) in INR",
    icon: (
      <img
        style={{ width: "20px", height: "20px", margin: "0 auto" }}
        src={icon2}
        alt="Live Demand"
      />
    ),
  },
  {
    name: "Live Demand-SO",
    key: "production-demand-SO",
    href: "/livedemand-So",
    description: "Live Demand from Sales Orders",
    icon: (
      <img
        style={{ width: "20px", height: "20px", margin: "0 auto" }}
        src={icon4}
        alt="Live Demand"
      />
    ),
  },
];
const marketingCollapse = [
  {
    name: "Pre-Approved Stock",
    key: "marketing",
    href: "/dashboard-marketing",
    description: "Stock & MTS Pending for Approval ",
    icon: (
      <img
        style={{ width: "20px", height: "20px", margin: "0 auto" }}
        src={icon10}
        alt="Live Demand"
      />
    ),
  },
  {
    name: "Budget",
    key: "marketing",
    href: "/dashboard/marketing/budgeting",
    description: "Annual Budget",
    icon: (
      <img
        style={{ width: "20px", height: "20px", margin: "0 auto" }}
        src={icon10}
        alt="Live Demand"
      />
    ),
  },
];
const marketingCollapse2 = [
  {
    name: "Budget",
    key: "marketing",
    href: "/dashboard/marketing/budgeting",
    description: "Annual Budget",
    icon: (
      <img
        style={{ width: "20px", height: "20px", margin: "0 auto" }}
        src={icon10}
        alt="Live Demand"
      />
    ),
  },
];
const page_Routes = [
  {
    name: "Demand",
    key: "demand",
    collapse: userRole === "admin" || userRole === "marketing" ? demandCollapse : demandCollapse2,
  },
  {
    name: "RM",
    key: "rm",
    collapse: [
      {
        name: "RM Live Demand",
        key: "rm-store1",
        href: "/purchase-rm",
        description: "Raw Material Demand",
        icon: (
          <img
            style={{ width: "20px", height: "20px", margin: "0 auto" }}
            src={icon7}
            alt="Live Demand"
          />
        ),
      },
      {
        name: "RM Detailed",
        key: "rm-store2",
        href: "/RMStore-2",
        description: "Raw Material Lead Time & EOQ",
        icon: (
          <img
            style={{ width: "20px", height: "20px", margin: "0 auto" }}
            src={icon8}
            alt="Live Demand"
          />
        ),
      },
    ],
  },
  {
    name: "Production",
    key: "production",
    collapse: [
      {
        name: "Live Demand Batches - SAP vs Budget",
        key: "production",
        href: "/dashboard-production",
        description: "Live Demand = Max of SO demand,Highest of SO Demand and Budget",
        icon: (
          <img
            style={{ width: "20px", height: "20px", margin: "0 auto" }}
            src={icon9}
            alt="Live Demand"
          />
        ),
      },
      {
        name: "Consolidated Batches - SAP vs Budget",
        key: "production",
        href: "/dashboard-production/consolidatedSheet",
        description: "Product Sub-Classes Consolidated",
        icon: (
          <img
            style={{ width: "20px", height: "20px", margin: "0 auto" }}
            src={icon9}
            alt="Live Demand"
          />
        ),
      },
      {
        name: "Live Demand Batches - SO",
        key: "production",
        href: "/dashboard-production/LiveDemandRaw",
        description: "Live Demand from Sales Orders ",
        icon: (
          <img
            style={{ width: "20px", height: "20px", margin: "0 auto" }}
            src={icon9}
            alt="Live Demand"
          />
        ),
      },
      {
        name: "Consolidated Batches - SO",
        key: "production",
        href: "/dashboard-production/actual/consolidatedSheet",
        description: "Consolidated Batches from ",
        icon: (
          <img
            style={{ width: "20px", height: "20px", margin: "0 auto" }}
            src={icon9}
            alt="Live Demand"
          />
        ),
      },
      {
        name: "Live Demand Batches - Confirmed",
        key: "production",
        href: "/dashboard-production/confirmed/liveDemand",
        description: "Live Demand from Confirmed SOs",
        icon: (
          <img
            style={{ width: "20px", height: "20px", margin: "0 auto" }}
            src={icon9}
            alt="Live Demand"
          />
        ),
      },
      {
        name: "Consolidated Batches - Confirmed",
        key: "production",
        href: "/dashboard-production/confirmed/consolidatedSheet",
        description: "Consolidated Batches from Confirmed SOs",
        icon: (
          <img
            style={{ width: "20px", height: "20px", margin: "0 auto" }}
            src={icon9}
            alt="Live Demand"
          />
        ),
      },
    ],
  },
  {
    name: "Upload",
    key: "upload",
    collapse: [
      {
        name: "Excel Upload",
        key: "excel upload",
        href: "/excel-upload",
        description: "Upload data excel ",
        icon: <SpaceShip size="15px" color="secondary" />,
      },
      // {
      //   name: "Upload History",
      //   key: "production-demand",
      //   href: "/list-upload",
      //   description: "History of uploaded files ",
      //   icon: <Document size="15px" color="secondary" />,
      // },
    ],
  },
  {
    name: "Marketing",
    key: "marketing",
    collapse: userRole === "production" ? marketingCollapse2 : marketingCollapse,

    // Replace 'someDefaultValue' with what you want to show if none of the conditions are met.
  },
  {
    name: "Contain Store",
    key: "containstore",
    collapse: [
      {
        name: "PM Live Demand",
        key: "contain-store1",
        href: "/containStore-1",
        description: "Packing Material Demand",
        icon: (
          <img
            style={{ width: "20px", height: "20px", margin: "0 auto" }}
            src={icon5}
            alt="Live Demand"
          />
        ),
      },
      {
        name: "PM Detailed",
        key: "contain-store2",
        href: "/containStore-2",
        description: "Packing Material Lead Time & EOQ",
        icon: (
          <img
            style={{ width: "20px", height: "20px", margin: "0 auto" }}
            src={icon6}
            alt="Live Demand"
          />
        ),
      },
    ],
  },
];
export default page_Routes;
