/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
// import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import SalesTable from "examples/Tables/SalesTable";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";
import Globe from "examples/Globe";

// Soft UI Dashboard PRO React base styles
import typography from "assets/theme/base/typography";
import breakpoints from "assets/theme/base/breakpoints";

// Data
import salesTableData from "layouts/dashboards/default/data/salesTableData";
import reportsBarChartData from "layouts/dashboards/default/data/reportsBarChartData";
import gradientLineChartData from "layouts/dashboards/default/data/gradientLineChartData";
import MuiTable from "examples/Tables/Mui Tables/MuiTables";
import { Box, Card, Chip, Link, Stack, Typography } from "@mui/material";
import SoftButton from "components/SoftButton";
import Paper from "@mui/material/Paper";
import SoftAlert from "components/SoftAlert";
import { Icon } from "@iconify/react";
import SoftSnackbar from "components/SoftSnackbar";
import { useEffect, useState } from "react";
import ResponsiveAppBar from "examples/Navbars/ResponsiveNavbar/ResponsiveNavbar";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import page_Routes from "page_routes";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

import { dispatch, useSelector } from "../../../redux/store";
import { getDemand } from "../../../redux/slices/demand";
import CustomSeparator from "examples/CustomSeprator/customSeprator";
import HomeIcon from "@mui/icons-material/Home";
import axios from "axios";
function Default() {
  // const API = process.env.server;
  const { values } = breakpoints;
  const { size } = typography;
  const { chart, items } = reportsBarChartData;
  const [show, setShow] = useState(true);
  const [data, setData] = useState();
  const [category, setCategory] = useState("all");
  const [sales_order_last_updated, setsales_order_last_updated] = useState(""); // State to store demand last updated
  const [sales_invoice_last_updated, setsales_invoice_last_updated] = useState(""); // State to store invoice last updated
  const [date, setDate] = useState(null);
  console.log("api", category);

  useEffect(() => {
    dispatch(getDemand(category));
    const getDate = async () => {
      try {
        const res = await axios.get("https://oushadhi-api.trigeminal-ai.com/date");
        console.log("API response:", res.data); // Log the entire response data
        const { sales_order_last_updated, sales_invoice_last_updated } = res.data;
        setsales_order_last_updated(sales_order_last_updated.date); // Update demand last updated with date
        setsales_invoice_last_updated(sales_invoice_last_updated.date);
      } catch (error) {
        console.error("Error fetching date:", error);
      }
    };

    getDate();
  }, [category]);
  console.log("first", data);
  const toggleSnackbar = () => {
    setShow(false);
  };

  const { demand } = useSelector((state) => state.demand);
  console.log("demand", demand);
  // const rowData = data.map((data, index) => {
  //   const slno = index;
  //   const item = data.item_name;
  //   const unit = "";
  //   const dealerNet = data.dlr;
  //   const PanchayathNet = data.gp;
  //   const govtNet = data.govt;
  //   const otgtNet = data.otgt;
  //   const total = "";

  //   return {
  //     slno,
  //     item,
  //     unit,
  //     dealerNet,
  //     PanchayathNet,
  //     govtNet,
  //     otgtNet,
  //     total,
  //   };
  // });

  const columnDefs = [
    {
      headerName: "Sl No",
      field: "sl_no",
      sortable: true,
      filter: true,
      width: "90px", // Width as a percentage
      pinned: "left",
    },
    {
      headerName: "Product Code",
      field: "item_code",
      sortable: true,
      filter: true,
      width: "150px", // Width as a percentage
      pinned: "left",
    },
    {
      headerName: "Product Description",
      field: "item_name",
      sortable: true,
      filter: true,
      width: "220px", // Width as a percentage
      pinned: "left",
    },
    {
      headerName: "Unit",
      field: "unit",
      sortable: true,
      filter: true,
      width: "120px", // Width as a percentage
      pinned: "left",
    },
    {
      headerName: "Govt",
      field: "govt",
      sortable: true,
      filter: true,
      flex: 0.75, // Flex for responsiveness
    },
    {
      headerName: "OTGT",
      field: "otgt",
      sortable: true,
      filter: true,
      flex: 0.75, // Flex for responsiveness
    },
    {
      headerName: "Dealer",
      field: "dlr",
      sortable: true,
      filter: true,
      flex: 0.8, // Flex for responsiveness
    },

    // {
    //   headerName: "GP",
    //   field: "gp",
    //   sortable: true,
    //   filter: true,
    //   flex: 0.75, // Flex for responsiveness
    // },
    {
      headerName: "Total",
      field: "total",
      sortable: true,
      filter: true,
      flex: 0.8, // Flex for responsiveness
    },
  ];

  const buttonArray = [
    "Asavarishtams",
    "Thylam",
    "Gritham",
    "Rasakriya",
    "Siddha",

    "Lehyam",
    "Choornam",
    "Bhasmam",
    "Gulika",
    "KC",
    "KSC",
    "Liquid Kashayam",
    "Patent Items",
  ];

  const breadcrumbs = [
    <Link underline="hover" key="1" color="#FEFFFF" href="/dashboards/default">
      <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" color="#FEFFFF" />
    </Link>,

    <Typography sx={{ fontSize: "13px", fontWeight: 800 }} key="3" color="#FEFFFF">
      Live Demand
    </Typography>,
  ];
  return (
    <>
      <DefaultNavbar
        routes={page_Routes}
        action={{
          type: "external",
          route: "https://creative-tim.com/product/soft-ui-dashboard-pro-react",
          label: "buy now",
        }}
        // transparent
        dark
      />
      {/* 
    // <DashboardLayout>
    // <DashboardNavbar />
      // <DashboardLayout>
      //  */}

      <SoftBox sx={{ marginTop: "68px", height: "100%" }} py={1}>
        <Grid container></Grid>
      </SoftBox>
      <Grid container>
        <Grid md={9.5}>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignContent: "flex-start",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                height: "40px",
                width: "100%",
                alignContent: "center",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ height: "30px" }}>
                <CustomSeparator breadcrumbs={breadcrumbs} />
              </Box>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignContent: "center",
                  marginRight: "40px",
                }}
              >
                <Chip
                  size="small"
                  label={`Demand  updated on: ${sales_order_last_updated}`}
                  color="success"
                  sx={{ bgcolor: "#439322", color: "white !important" }}
                />
                <Chip
                  size="small"
                  label={`Invoice  updated on: ${sales_invoice_last_updated}`}
                  color="success"
                  sx={{ marginLeft: "10px", bgcolor: "#439322", color: "white !important" }}
                />
              </div>
            </div>
            <div className="ag-theme-alpine" style={{ height: "77vh", width: "94%", flexGrow: 1 }}>
              <AgGridReact
                paginationPageSize={20}
                rowData={demand}
                columnDefs={columnDefs}
                pagination={true}
              />
            </div>
          </div>
        </Grid>
        <Grid md={2.5}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyConntent: "center",
              alignItem: "flexEnd",
              alignContent: "flexEnd",
              gap: "10px",
              paddingInline: "50px",
            }}
            py={5}
            mb={1}
          >
            {buttonArray.map((label, index) => (
              <SoftButton
                onClick={() => {
                  label === category ? setCategory("all") : setCategory(label);
                }}
                key={index}
                variant="gradient"
                color={label === category ? "success" : "info"}
              >
                {label}
              </SoftButton>
            ))}
          </Box>
        </Grid>
      </Grid>
      {/* <SoftSnackbar
        color="warning"
        icon="notifications"
        title="Alert"
        content="Daily demand update pending"
        // dateTime="11 mins ago"
        open={show}
        close={toggleSnackbar}
      /> */}
      {/* // <Footer />
    // </DashboardLayout> */}
    </>
  );
}

export default Default;
