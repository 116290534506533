import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";
import illustration from "assets/images/illustrations/Oushadhi Asset.png";
import SoftSnackbar from "components/SoftSnackbar";
import axios from "axios";

function Illustration() {
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [showSnackbar, setShowSnackbar] = useState(false); // Changed from 'show' to 'showSnackbar'
  const API = process.env.REACT_APP_API_KEY;

  const [token, setToken] = useState(localStorage.getItem("token"));

  // useEffect(() => {
  //   const checkTokenExpiration = async () => {
  //     try {
  //       const response = await axios.post(`${API}/auth/refresh-token`, { accesstoken: token });
  //       const newToken = response.data.access_token;
  //       setToken(newToken);
  //       localStorage.setItem("token", newToken);
  //     } catch (error) {
  //       console.error("Error refreshing token:", error);
  //       // Handle token refresh error, e.g., redirect to sign-in page
  //       navigate("/authentication/sign-in/illustration");
  //     }
  //   };

  //   // Check token expiration on component mount and token change
  //   if (token) {
  //     checkTokenExpiration();
  //   } else {
  //     // If token doesn't exist, redirect to sign-in page
  //     navigate("/authentication/sign-in/illustration");
  //   }
  // }, [token, navigate, API]);

  const handleLogin = async () => {
    try {
      const response = await axios.post(`${API}/auth/login`, {
        useremail: userName,
        password: password,
      });
      const userRole = response.data.user_role;
      if (response.status) {
        localStorage.setItem("userRole", userRole);
        const accessToken = response.data.access_token;
        setToken(accessToken);
        localStorage.setItem("token", accessToken);
        window.location.href = "/dashboards/default";
      }
    } catch (error) {
      console.error("Login error:", error);
      setShowSnackbar(true);
    }
  };

  const closeSnackbar = () => setShowSnackbar(false);

  return (
    <>
      <SoftSnackbar
        color="error"
        icon="notifications"
        title="Alert"
        content="Invalid Credentials"
        open={showSnackbar}
        close={closeSnackbar}
      />
      <IllustrationLayout
        title="Sign In"
        description="Enter your email and password to sign in"
        illustration={{
          image: illustration,
          title: "Oushadhi Live Demand App",
          description: "Empowering Efficiency, Elevating Excellence: Our Priority!",
        }}
      >
        <SoftBox component="form" role="form">
          <SoftBox mb={2}>
            <SoftInput
              onChange={(e) => setUserName(e.target.value)}
              type="email"
              placeholder="Email"
              size="large"
              value={userName}
            />
          </SoftBox>
          <SoftBox mb={2}>
            <SoftInput
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              placeholder="Password"
              size="large"
              value={password}
            />
          </SoftBox>
          <SoftBox mt={4} mb={1}>
            <SoftButton
              onClick={handleLogin}
              variant="gradient"
              color="info"
              size="large"
              fullWidth
            >
              Sign In
            </SoftButton>
          </SoftBox>
          <SoftBox mt={3} textAlign="center">
            <SoftTypography variant="button" color="text" fontWeight="regular">
              Don&apos;t have an account?{" "}
              <SoftTypography
                component={Link}
                to="/authentication/sign-up/illustration"
                variant="button"
                color="info"
                fontWeight="medium"
                textGradient
              >
                Sign up
              </SoftTypography>
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      </IllustrationLayout>
    </>
  );
}

export default Illustration;
